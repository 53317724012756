import React, { useState, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container } from '@material-ui/core'
import { H3, WistiaVideoOverlay } from '@system'
import playIcon from '@images/play-icon-inverted.svg'
import WistiaVideoDetailsService from '@hooks/get-wistia-video-details'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.slite,
      width: '97%',
      height: '71px',
      display: 'flex',
      padding: 'unset',
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        width: '97%',
        margin: '0 auto',
      },
      '& .wistia_embed': {
        margin: '0 auto',
        maxWidth: '577px',
        maxHeight: '71px',
      },
    },
    container: {
      display: 'flex',
      paddingTop: 25,
      paddingBottom: 25,
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        paddingTop: '15px',
      },
    },
    thumbnailContainer: {
      height: '71px',
      width: '128px',
      backgroundColor: theme.palette.primary.main,
    },
    bannerText: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightRegular,
      justifyContent: 'center',
      margin: '0 auto',
      fontSize: '16px',
      paddingRight: '1rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        textAlign: 'left',
        maxWidth: '121px',
        margin: 0,
      },
    },
    icon: {
      height: '20px',
      width: '33px',
      [theme.breakpoints.down('xs')]: {
        paddingTop: '12px',
      },
    },
    image: {
      height: '71px',
      width: '128px',
    },
    time: {
      height: '20px',
      width: '40px',
      textAlign: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      borderRadius: '2px',
      fontSize: '10px',
      paddingTop: '3px',
      color: theme.palette.text.white,
      position: 'relative',
      bottom: '30px',
      left: '7px',
    },
  }
})

const formatTime = (time) => {
  const formatted = Math.round(time / 60)
  return `${formatted} MIN`
}

const VideoCtaBanner = (props) => {
  const classes = useStyles()
  const [details, setDetails] = useState()
  const { bannerText, wistiaVideoId } = props.blok

  const asyncDetails = WistiaVideoDetailsService(wistiaVideoId)

  useEffect(() => {
    if (asyncDetails && typeof asyncDetails === 'object') {
      setDetails(asyncDetails)
    }
  }, [asyncDetails])

  return (
    !!details && (
      <SbEditable content={props.blok}>
        <Container className={classes.root}>
          {details?.thumbnail?.url && details?.duration && (
            <Box className={classes.thumbnailContainer}>
              <img
                src={details.thumbnail.url}
                alt="banner thumbnail"
                className={classes.image}
              />
              <Box className={classes.time}>{formatTime(details.duration)}</Box>
            </Box>
          )}
          <Container className={classes.container}>
            <H3 className={classes.bannerText}>{bannerText}</H3>
            <Box className={classes.icon}>
              <img alt="play icon" src={playIcon} />
            </Box>
          </Container>
          <WistiaVideoOverlay videoId={wistiaVideoId} />
        </Container>
      </SbEditable>
    )
  )
}

export default VideoCtaBanner
